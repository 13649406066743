import React from 'react';
import { Layout, Typography } from 'antd';
import AppHeader from './AppHeader';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const EventInfo = () => {
  return (
    <Layout>
      <AppHeader />
      <Content style={{ padding: '0 50px', marginTop: '20px' }}>
        <Title level={2}>Eventinformation</Title>
        <Paragraph>
          <strong>Datum:</strong> 21/9 2024
        </Paragraph>
        <Title level={3}>Hålltider</Title>
        <Paragraph>
          <strong>Arbetsdag börjar:</strong> 09:00
        </Paragraph>
        <Paragraph>
          <strong>Bryggare anländer:</strong> 11:00
        </Paragraph>
        <Paragraph>
          <strong>Publiken släpps in:</strong> 12:00
        </Paragraph>
        <Paragraph>
          <strong>Eventet stänger:</strong> 17:00
        </Paragraph>
        <Paragraph>
          <strong>Arbetsdag slut:</strong> ca 20:00
        </Paragraph>
        <Paragraph>
          <strong>Plats:</strong> Hönö, Östra Hamnen. <a href="https://maps.app.goo.gl/uKQ8wqwxhsqcZNwx5">Lat: 57.682719, Lon: 11.650625</a> <br/>

        </Paragraph>
        <Title level={3}>Information</Title>
        <Paragraph>
          Bryggare som har möjlighet får gärna komma mellan kl 9 och 11 för att hjälpa till att resa tält och göra iorning eventplatsen. <br />
          Mellan kl 11 och 12 har vi provning för bryggare där vi får gå runt och provsmaka av varandra. <br />
          Bord kommer att finnas till alla, har ni med egen bar skall detta anmälas i din profil. Bryggare ansvarar för att ha med allt för att servera sin öl. <br />
          Efter eventet skall vi plocka ner tält och göra iorning området, all hjälp är välkommen för att detta skall gå så smidigt som möjligt. <br />
        </Paragraph>
        <Paragraph>
          <strong>Kontakt: </strong> <a href="mailto:info@honoolagille.se">info@honoolagille.se</a> eller chat via FB.
        </Paragraph>
        {/* Add more detailed information as needed */}
      </Content>
    </Layout>
  );
};

export default EventInfo;
