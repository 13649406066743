import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import beerService from '../services/beerService';

const AddBeer = () => {
  const [name, setName] = useState('');
  const [style, setStyle] = useState('');
  const [abv, setAbv] = useState('');
  const [ibu, setIbu] = useState('');
  const [ebc, setEbc] = useState('');
  const [og, setOg] = useState('');
  const [fg, setFg] = useState('');
  const [brewDate, setBrewDate] = useState('');
  const navigate = useNavigate();

  const handleAddBeer = async (e) => {
    e.preventDefault();
    try {
      await beerService.addBeer({ name, style, abv, ibu, ebc, og, fg, brew_date: brewDate });
      navigate('/beers');
    } catch (error) {
      console.error('Add beer error', error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box mt={5}>
        <Typography variant="h4" gutterBottom>Add New Beer</Typography>
        <form onSubmit={handleAddBeer}>
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="Style"
            fullWidth
            margin="normal"
            value={style}
            onChange={(e) => setStyle(e.target.value)}
          />
          <TextField
            label="ABV"
            fullWidth
            margin="normal"
            value={abv}
            onChange={(e) => setAbv(e.target.value)}
          />
          <TextField
            label="IBU"
            fullWidth
            margin="normal"
            value={ibu}
            onChange={(e) => setIbu(e.target.value)}
          />
          <TextField
            label="EBC"
            fullWidth
            margin="normal"
            value={ebc}
            onChange={(e) => setEbc(e.target.value)}
          />
          <TextField
            label="OG"
            fullWidth
            margin="normal"
            value={og}
            onChange={(e) => setOg(e.target.value)}
          />
          <TextField
            label="FG"
            fullWidth
            margin="normal"
            value={fg}
            onChange={(e) => setFg(e.target.value)}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Add Beer
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default AddBeer;
