const beerStyles = {
  '0': {
    label: 'Ej angett',
    options: {
      'A': 'Ej angett'
    }
  },
  '1': {
      label: 'Mild/karaktärsfull Lager',
      options: {
        'A': 'Helles',
        'B': 'Export',
        'C': 'Märzen',
        'D': 'Frankisk lager',
        'E': 'Centraleuropeisk mörk lager',
        'F': 'Schwarzbier',
        'G': 'Tjeckisk pilsner',
        'H': 'Tysk pilsner',
        'I': 'Internationell lager',
        'J': 'Övriga klassiska'
      }
    },
    '2': {
      label: 'Kraftig Lager',
      options: {
        'A': 'Bock',
        'B': 'Dubbelbock',
        'C': 'Modern Ljus Lager',
        'D': 'Modern Mörk Lager',
        'E': 'Övriga klassiska'
      }
    },
    '3': {
      label: 'Mild ale',
      options: {
        'A': 'Engelsk dark mild',
        'B': 'Kölsch',
        'C': 'Irländsk red ale',
        'D': 'Skotsk ale 70/- & 80/-',
        'E': 'Brittisk brown ale',
        'F': 'English ordinary bitter',
        'G': 'Övriga klassiska'
      }
    },
    '4': {
      label: 'Karaktärsfull ale',
      options: {
        'A': 'Engelsk best bitter',
        'B': 'English strong bitter - ESB',
        'C': 'Pale ale (APA)',
        'D': 'Brown ale (amerikansk)',
        'E': 'Engelsk IPA',
        'F': 'Altbier',
        'G': 'Övriga klassiska'
      }
    },
    '5': {
      label: 'Kraftig ale',
      options: {
        'A': 'Scottish strong ale, 90/-',
        'B': 'Strong pale ale',
        'C': 'IPA',
        'D': 'New England IPA - Vermont IPA',
        'E': 'Black IPA',
        'F': 'Dubbel IPA',
        'G': 'Barley wine',
        'H': 'Övriga klassiska'
      }
    },
    '6': {
      label: 'Veteöl',
      options: {
        'A': 'Ljus veteöl av sydtysk typ',
        'B': 'Klassisk veteöl av sydtysk typ',
        'C': 'Mörkt veteöl av sydtysk typ',
        'D': 'Belgisk wit',
        'E': 'Weizenbock',
        'F': 'Hopfenweizen',
        'G': 'Övriga klassiska'
      }
    },
    '7': {
      label: 'Mild Stout/Porter',
      options: {
        'A': 'Dry stout',
        'B': 'Extra stout',
        'C': 'Brown porter',
        'D': 'Porter',
        'E': 'Oatmeal stout',
        'F': 'Milk/Sweet Stout',
        'G': 'Övriga klassiska'
      }
    },
    '8': {
      label: 'Kraftig Stout/Porter',
      options: {
        'A': 'Foreign stout',
        'B': 'Modern Stout & Porter',
        'C': 'Imperial porter',
        'D': 'Imperial stout',
        'E': 'Övriga klassiska'
      }
    },
    '9': {
      label: 'Belgisk och fransk ale',
      options: {
        'A': 'Ljus belgisk ale',
        'B': 'Blonde',
        'C': 'Tripel',
        'D': 'Ljus stark belgisk ale',
        'E': 'Brune',
        'F': 'Dubbel',
        'G': 'Mörk stark belgisk ale',
        'H': 'Saison',
        'I': 'Bière de garde',
        'J': 'Supersaison',
        'K': 'Belgiskt och franskt specialöl',
        'L': 'Historiskt belgiskt öl'
      }
    },
    '10': {
      label: 'Syrliga öl',
      options: {
        'A': 'Lambik',
        'B': 'Gueze',
        'C': 'Fruktlambik',
        'D': 'Oud bruin',
        'E': 'Berliner weisse',
        'F': 'Övriga klassiska'
      }
    },
    '11': {
      label: 'Specialöl',
      options: {
        'A': 'Frukt, bär och grönsaksöl',
        'B': 'Kryddöl',
        'C': 'Brettanomycesöl',
        'D': 'Modifierade öl',
        'E': 'Porter/Stout special',
        'F': 'Rököl, typ Bamberg',
        'G': 'Rökbock',
        'H': 'Övrig rököl',
        'I': 'Trä- och fatlagrade öl',
        'J': 'Gotlandsdricke [Haimbryggt]',
        'K': 'Eisbock',
        'L': 'Övriga, övriga klassiska öltyper'
      }
    },
    '12': {
      label: 'Övriga öl',
      options: {
        'A': 'Fruktsuris',
        'B': 'Italopils',
        'C': 'Cold IPA',
        'D': 'Råg-IPA',
        'E': 'Brut-IPA'
      }
    }
  };
  
  export default beerStyles;
  