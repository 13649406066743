import axios from 'axios';

const API_URL = 'https://traffen.honoolagille.se/api';

const getBeers = () => {
  const token = localStorage.getItem('token');
  return axios.get(`${API_URL}/beers`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const addBeer = (beer) => {
  const token = localStorage.getItem('token');
  return axios.post(`${API_URL}/beers`, beer, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const getBeer = (id) => {
  const token = localStorage.getItem('token');
  return axios.get(`${API_URL}/beers/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const updateBeer = (id, beer) => {
  const token = localStorage.getItem('token');
  return axios.put(`${API_URL}/beers/${id}`, beer, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const deleteBeer = (id) => {
  const token = localStorage.getItem('token');
  return axios.delete(`${API_URL}/beers/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const getAll_Beers = () => {
  const token = localStorage.getItem('token');
  return axios.get(`${API_URL}/all-beers`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const getAllBeers = () => {
  const token = localStorage.getItem('token');
  return axios.get(`${API_URL}/allbeers`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export default {
  getBeers,
  addBeer,
  getBeer,
  updateBeer,
  deleteBeer,
  getAllBeers,
  getAll_Beers,
};
