import axios from 'axios';

const API_URL = 'https://traffen.honoolagille.se/api';

const register = (user) => {
  return axios.post(`${API_URL}/register`, user);
};

const login = (email, password) => {
  return axios.post(`${API_URL}/login`, { email, password });
};

const logout = () => {
  return axios.post(`${API_URL}/logout`, {}, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });
};

const getProfile = async () => {
  const response = await axios.get(`${API_URL}/profile`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });
  return response.data;
};

const updateProfile = async (profileData) => {
  const response = await axios.put(`${API_URL}/profile`, profileData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });
  return response.data;
};

export default {
  register,
  login,
  logout,
  getProfile,
  updateProfile,
};
