import React from 'react';
import { Layout, Row, Col, Typography, Dropdown, Button, Menu, Modal, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import authService from '../services/authService';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;
const { Header } = Layout;

const AppHeader = ({ screens, showProfileModal }) => {
  const navigate = useNavigate();

  const showLogoutConfirm = () => {
    Modal.confirm({
      title: 'Är du säker på att du vill logga ut?',
      content: 'Du kommer att behöva logga in igen för att få tillgång till sidan.',
      onOk() {
        handleLogout();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleLogout = async () => {
    try {
      await authService.logout();
      localStorage.removeItem('token');
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
      message.error('Error logging out. Please try again.');
    }
  };

  const profileMenu = (
    <Menu>
      <Menu.Item key="1" onClick={showProfileModal}>
        Ändra profil
      </Menu.Item>
      <Menu.Item key="2" onClick={() => navigate('/beers')}>
        Mina öl
      </Menu.Item>
      <Menu.Item key="3" onClick={() => navigate('/allbeers')}>
        Alla öl
      </Menu.Item>
      <Menu.Item key="4" onClick={() => navigate('/eventinfo')}>
        Eventinformation
      </Menu.Item>
      <Menu.Item key="5" onClick={showLogoutConfirm}>
        Logga ut
      </Menu.Item>
    </Menu>
  );

  return (
    <Header style={{ background: '#001529', padding: '0 20px' }}>
      <Row justify="space-between" align="middle">
        <Col>
          <Title style={{ color: 'white', margin: 0, fontSize: screens?.xs ? '16px' : '24px' }}>
            Registrering Hönö Ölagilles Höstölsträff 2024
          </Title>
        </Col>
        <Col>
          <Dropdown overlay={profileMenu} placement="bottomRight">
            <Button shape="circle" icon={<UserOutlined />} />
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
};

export default AppHeader;
