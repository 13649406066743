import React, { useEffect, useState } from 'react';
import { Table, Button, Layout, Row, Col, Typography, message, Grid } from 'antd';
import { useNavigate } from 'react-router-dom';
import beerService from '../services/beerService';
import AppHeader from '../components/AppHeader';
import beerStyles from './beerStyles';
import '../App.css';

const { Title } = Typography;
const { Content } = Layout;
const { useBreakpoint } = Grid;

const AllBeers = () => {
  const [beers, setBeers] = useState([]);
  const screens = useBreakpoint();
  const navigate = useNavigate();

  useEffect(() => {
    fetchAllBeers();
  }, []);

  const fetchAllBeers = async () => {
    try {
      const response = await beerService.getAllBeers();
      if (response.data && response.data.beers) {
        setBeers(response.data.beers);
      } else {
        message.error('Failed to load beers data');
      }
    } catch (error) {
      console.error('Error fetching all beers:', error);
      message.error('Error fetching all beers. Please try again.');
    }
  };

  const getFullStyleLabel = (style) => {
    if (!style) return 'Unknown style';
    const match = style.match(/(\d+)([A-Z])/);
    if (!match) return 'Invalid style';
    const [category, subcategory] = match.slice(1);
    const categoryObj = beerStyles[category];
    const subcategoryLabel = categoryObj ? categoryObj.options[subcategory] : 'Unknown subcategory';
    return `${style}: ${subcategoryLabel}`;
  };

  const styleSorter = (a, b) => {
    const aMatch = a.style.match(/(\d+)([A-Z])/);
    const bMatch = b.style.match(/(\d+)([A-Z])/);
    if (!aMatch || !bMatch) return 0;

    const aCategory = parseInt(aMatch[1], 10);
    const bCategory = parseInt(bMatch[1], 10);

    if (aCategory !== bCategory) {
      return aCategory - bCategory;
    }

    const aSubcategory = aMatch[2];
    const bSubcategory = bMatch[2];

    return aSubcategory.localeCompare(bSubcategory);
  };


  const columns = [
    {
      title: 'Namn',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Stil',
      dataIndex: 'style',
      key: 'style',
      sorter: styleSorter,
      render: (style) => getFullStyleLabel(style),
    },
    {
      title: 'ABV',
      dataIndex: 'abv',
      key: 'abv',
      sorter: (a, b) => a.abv - b.abv,
    },
    {
      title: 'IBU',
      dataIndex: 'ibu',
      key: 'ibu',
      sorter: (a, b) => a.ibu - b.ibu,
    },
    {
      title: 'EBC',
      dataIndex: 'ebc',
      key: 'ebc',
      sorter: (a, b) => a.ebc - b.ebc,
    },
    {
      title: 'OG',
      dataIndex: 'og',
      key: 'og',
      sorter: (a, b) => a.og - b.og,
    },
    {
      title: 'FG',
      dataIndex: 'fg',
      key: 'fg',
      sorter: (a, b) => a.fg - b.fg,
    },
    {
      title: 'Bryggare ID',
      dataIndex: 'brewer_id',
      key: 'brewer_id',
      sorter: (a, b) => a.brewer_id - b.brewer_id,
    },
  ];

  return (
    <Layout>
      <AppHeader screens={screens} />
      <Content style={{ padding: screens.xs ? '0 20px' : '0 50px' }}>
        <Row justify="start" style={{ marginTop: '20px' }}>
          <Col>
            <Button type="primary" onClick={() => navigate('/beers')}>
              Tillbaka till mina öl
            </Button>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: '20px' }}>
          <Col>
            <Title level={2}>Alla öl</Title>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={beers}
          rowKey={(record) => record.id}
          pagination={false}
          scroll={{ x: 600 }}
        />
      </Content>
    </Layout>
  );
};

export default AllBeers;
