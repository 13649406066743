import React, { useState } from 'react';
import { Form, Input, Button, message, Typography, Layout, Card, Checkbox } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../App.css'; // Ensure this contains your global styles

const { Title } = Typography;
const { Content } = Layout;

const API_URL = 'https://traffen.honoolagille.se/api/register';

const Register = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      const response = await axios.post(API_URL, values);
      if (response.status === 201) {
        message.success('Brewer registered successfully');
        form.resetFields();
        navigate('/login'); // Redirect to login page
      }
    } catch (error) {
      console.error('Error registering brewer:', error);
      message.error('Registration failed. Please try again.');
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
    message.error('Please complete all required fields.');
  };

  return (
    <Layout style={{ minHeight: '100vh', justifyContent: 'center', alignItems: 'center' }}>
      <Content style={{ maxWidth: '400px', width: '100%' }}>
        <Card style={{ padding: '20px', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0,0,0,0.1)' }}>
          <Title level={2} style={{ textAlign: 'center' }}>Registrera konto</Title>
          <Form
            form={form}
            name="register"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Form.Item
              label="Namn"
              name="name"
              rules={[{ required: true, message: 'Var vänlig ange ditt namn!' }]}
            >
              <Input placeholder="Ditt fullständiga namn" />
            </Form.Item>

            <Form.Item
              label="E-post"
              name="email"
              rules={[{ required: true, message: 'Var vänlig ange din e-post!' }]}
            >
              <Input placeholder="Din E-postadress" />
            </Form.Item>

            <Form.Item
              label="Telefonnummer"
              name="phone_nr"
              rules={[{ required: true, message: 'Var vänlig ange ditt telefonnummer!' }]}
            >
              <Input placeholder="Ditt telefonnummer" />
            </Form.Item>

            <Form.Item
              label="Lösenord"
              name="password"
              rules={[{ required: true, message: 'Var vänlig ange ditt lösenord!' }]}
            >
              <Input.Password placeholder="Välj lösenord" />
            </Form.Item>

            <Form.Item
              label="Hembryggnarklubb"
              name="club"
            >
              <Input placeholder="Hembryggarklubb (ej krav)" />
            </Form.Item>
            <Form.Item
              name="brings_own_bar"
              valuePropName="checked"
            >
            <Checkbox>Tar med egen bar</Checkbox>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Registrera
              </Button>
            </Form.Item>

          </Form>
        </Card>
      </Content>
    </Layout>
  );
};

export default Register;
