import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import BeerTable from './components/BeerTable';
import AddBeer from './components/AddBeer';
import EditBeer from './components/EditBeer';
import ProtectedRoute from './components/ProtectedRoute';
import BeerList from './components/BeerList';
import './App.css';
import AllBeers from './components/AllBeers';
import EventInfo from './components/EventInfo';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/beers" element={<BeerTable />} />
          <Route path="/add-beer" element={<AddBeer />} />
          <Route path="/edit-beer/:id" element={<EditBeer />} />
          <Route path="/beerlist" element={<BeerList />} />
          <Route path="/allbeers" element={<AllBeers />} />
          <Route path="/eventinfo" element={<EventInfo />} />
        </Route>
        <Route path="/" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
