import React, { useEffect, useState } from 'react';
import {
  Table,
  Button,
  Input,
  Typography,
  Space,
  Layout,
  Row,
  Col,
  message,
  Modal,
  Grid,
  Form,
  Checkbox
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import beerService from '../services/beerService';
import authService from '../services/authService';
import beerStyles from './beerStyles';
import '../App.css';
import AppHeader from '../components/AppHeader';

const { Title } = Typography;
const { Content } = Layout;
const { confirm } = Modal;
const { useBreakpoint } = Grid;

const BeerTable = () => {
  const [beers, setBeers] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [newBeer, setNewBeer] = useState(null);
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
  const [isHelpModalVisible, setIsHelpModalVisible] = useState(false); // State for help modal
  const [profileData, setProfileData] = useState({ name: '', email: '', club: '', phone: '', brings_own_bar: false });
  const screens = useBreakpoint();
  const navigate = useNavigate();

  useEffect(() => {
    fetchBeers();
    fetchProfileData();
  }, []);

  const fetchBeers = async () => {
    try {
      const response = await beerService.getBeers();
      setBeers(response.data.beers);
    } catch (error) {
      console.error('Error fetching beers:', error);
    }
  };

  const fetchProfileData = async () => {
    try {
      const response = await authService.getProfile();
      setProfileData(response);
    } catch (error) {
      console.error('Error fetching profile data:', error);
    }
  };

  const handleEdit = (index) => {
    setEditIndex(index);
  };

  const handleSave = async (id, index) => {
    try {
      const updatedBeer = beers[index];
      await beerService.updateBeer(id, updatedBeer);
      setEditIndex(null);
      fetchBeers();
    } catch (error) {
      console.error('Error updating beer:', error);
    }
  };

  const handleChange = (index, field, value) => {
    const sanitizedValue = value.replace(',', '.'); // Replace comma with dot
    if ((field === 'og' || field === 'fg') && parseFloat(sanitizedValue) > 1.999) {
      message.error('OG och FG får inte vara högre än 1.999');
      return;
    }

    if (newBeer && index === beers.length) {
      setNewBeer({ ...newBeer, [field]: sanitizedValue });
    } else {
      const updatedBeers = [...beers];
      updatedBeers[index][field] = sanitizedValue;
      setBeers(updatedBeers);
    }
  };

  const handleAddBeer = () => {
    const newBeerIndex = beers.length;
    setNewBeer({
      name: '',
      style: '',
      abv: '',
      ibu: '',
      ebc: '',
      og: '',
      fg: '',
      cobrewer: '',
    });
    setEditIndex(newBeerIndex);
  };

  const handleSaveNewBeer = async () => {
    if (!newBeer.name) {
      message.error('Du måste ange ett namn');
      return;
    }

    // Convert empty strings to null
    const sanitizedBeer = {
      ...newBeer,
      style: newBeer.style || null,
      abv: newBeer.abv || null,
      ibu: newBeer.ibu || null,
      ebc: newBeer.ebc || null,
      og: newBeer.og || null,
      fg: newBeer.fg || null,
      cobrewer: newBeer.cobrewer || null,
    };

    try {
      await beerService.addBeer(sanitizedBeer);
      setNewBeer(null);
      setEditIndex(null);
      fetchBeers();
    } catch (error) {
      console.error('Error adding beer:', error);
    }
  };

  const handleCancelNewBeer = () => {
    setNewBeer(null);
    setEditIndex(null);
  };

  const handleDeleteBeer = async (id) => {
    try {
      await beerService.deleteBeer(id);
      fetchBeers();
    } catch (error) {
      console.error('Error deleting beer:', error);
    }
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Är du säker på att du vill ta bort denna öl?',
      content: 'Detta går inte att ångra (du kan skapa ny)',
      onOk() {
        handleDeleteBeer(id);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const getStyleLabel = (style) => {
    if (!style) return 'Unknown style';
    const match = style.match(/(\d+)([A-Z])/);
    if (!match) return 'Invalid style';
    const [category, subcategory] = match.slice(1);
    const categoryObj = beerStyles[category];
    const subcategoryLabel = categoryObj ? categoryObj.options[subcategory] : 'Unknown subcategory';
    return `${style} (${subcategoryLabel})`;
  };

  const handleNumberInputKeyPress = (e) => {
    if (!/[0-9.,]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleProfileUpdate = async () => {
    try {
      await authService.updateProfile(profileData);
      message.success('Profilen har uppdaterats');
      setIsProfileModalVisible(false);
    } catch (error) {
      console.error('Error updating profile:', error);
      message.error('Error updating profile. Please try again.');
    }
  };

  const styleOptions = Object.entries(beerStyles).map(([key, category]) => ({
    label: category.label,
    options: Object.entries(category.options).map(([subKey, label]) => ({
      value: `${key}${subKey}`,
      label: `${key}${subKey} - ${label}`,
    })),
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: '200px',
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  const getTavlingsID = (record) => {
    return record.id ? `${record.style}:${record.id}` : '';
  };

  const columns = [
    {
      title: 'Namn',
      dataIndex: 'name',
      render: (text, record, index) => (
        (editIndex === index || (newBeer && index === beers.length)) ? (
          <Input
            value={record.name || newBeer?.name || ''}
            onChange={(e) => handleChange(index, 'name', e.target.value)}
            style={{ minWidth: '100px', width: '100%' }}
            placeholder="Namn"
          />
        ) : text
      ),
    },
    {
      title: 'Stil',
      dataIndex: 'style',
      render: (text, record, index) => (
        (editIndex === index || (newBeer && index === beers.length)) ? (
          <Select
            options={styleOptions}
            value={styleOptions.flatMap(group => group.options).find(option => option.value === (record.style || newBeer?.style))}
            onChange={(selectedOption) => handleChange(index, 'style', selectedOption.value)}
            placeholder="Välj stil"
            isSearchable
            styles={customStyles}
            menuPortalTarget={document.body}
          />
        ) : getStyleLabel(text)
      ),
    },
    {
      title: 'ABV',
      dataIndex: 'abv',
      render: (text, record, index) => (
        (editIndex === index || (newBeer && index === beers.length)) ? (
          <Input
            value={record.abv || newBeer?.abv || ''}
            onChange={(e) => handleChange(index, 'abv', e.target.value)}
            onKeyPress={handleNumberInputKeyPress}
            style={{ minWidth: '100px', width: '100%' }}
            placeholder="5.4"
          />
        ) : text
      ),
    },
    {
      title: 'IBU',
      dataIndex: 'ibu',
      render: (text, record, index) => (
        (editIndex === index || (newBeer && index === beers.length)) ? (
          <Input
            value={record.ibu || newBeer?.ibu || ''}
            onChange={(e) => handleChange(index, 'ibu', e.target.value)}
            onKeyPress={handleNumberInputKeyPress}
            style={{ minWidth: '100px', width: '100%' }}
            placeholder="35"
          />
        ) : text
      ),
    },
    {
      title: 'EBC',
      dataIndex: 'ebc',
      render: (text, record, index) => (
        (editIndex === index || (newBeer && index === beers.length)) ? (
          <Input
            value={record.ebc || newBeer?.ebc || ''}
            onChange={(e) => handleChange(index, 'ebc', e.target.value)}
            onKeyPress={handleNumberInputKeyPress}
            style={{ minWidth: '100px', width: '100%' }}
            placeholder="12"
          />
        ) : text
      ),
    },
    {
      title: 'OG',
      dataIndex: 'og',
      render: (text, record, index) => (
        (editIndex === index || (newBeer && index === beers.length)) ? (
          <Input
            value={record.og || newBeer?.og || ''}
            onChange={(e) => handleChange(index, 'og', e.target.value)}
            onKeyPress={handleNumberInputKeyPress}
            style={{ minWidth: '100px', width: '100%' }}
            placeholder="1.050"
          />
        ) : text
      ),
    },
    {
      title: 'FG',
      dataIndex: 'fg',
      render: (text, record, index) => (
        (editIndex === index || (newBeer && index === beers.length)) ? (
          <Input
            value={record.fg || newBeer?.fg || ''}
            onChange={(e) => handleChange(index, 'fg', e.target.value)}
            onKeyPress={handleNumberInputKeyPress}
            style={{ minWidth: '100px', width: '100%' }}
            placeholder="1.012"
          />
        ) : text
      ),
    },
    {
      title: 'Medbryggare',
      dataIndex: 'cobrewer',
      render: (text, record, index) => (
        (editIndex === index || (newBeer && index === beers.length)) ? (
          <Input
            value={record.cobrewer || newBeer?.cobrewer || ''}
            onChange={(e) => handleChange(index, 'cobrewer', e.target.value)}
            style={{ minWidth: '100px', width: '100%' }}
            placeholder="Medbryggare"
          />
        ) : text
      ),
    },
    {
      title: 'Åtgärder',
      render: (text, record, index) => (
        editIndex === index ? (
          record.id ? (
            <Space>
              <Button onClick={() => handleSave(record.id, index)}>
                Spara
              </Button>
              <Button onClick={handleCancelNewBeer}>
                Avbryt
              </Button>
            </Space>
          ) : (
            <Space>
              <Button onClick={handleSaveNewBeer}>
                Spara ny öl
              </Button>
              <Button onClick={handleCancelNewBeer}>
                Avbryt
              </Button>
            </Space>
          )
        ) : (
          <Space>
            <Button onClick={() => handleEdit(index)}>
              Ändra
            </Button>
            {record.id && (
              <Button onClick={() => showDeleteConfirm(record.id)} danger>
                Ta bort
              </Button>
            )}
          </Space>
        )
      ),
    },
    {
      title: 'TävlingsID',
      key: 'tavlingsid',
      render: (text, record) => getTavlingsID(record),
    },
  ];

  return (
    <Layout>
      <AppHeader screens={screens} showProfileModal={() => setIsProfileModalVisible(true)} />
      <Content style={{ padding: screens.xs ? '0 20px' : '0 50px' }}>
        <Row justify="center" style={{ marginTop: '20px' }}>
          <Col>
            <Title level={2}>Mina öl</Title>
          </Col>
        </Row>
        <div className="help-button-container">
          <Button
            shape="circle"
            icon={<QuestionCircleOutlined />}
            onClick={() => setIsHelpModalVisible(true)}
          />
        </div>
        <div className="table-container">
          <Table
            columns={columns}
            dataSource={newBeer ? [...beers, newBeer] : beers}
            rowKey={(record) => record.id || 'new'}
            pagination={false}
            scroll={{ x: 600 }} // Enable horizontal scrolling if needed
          />
        </div>
        <Row justify="center" style={{ marginTop: '20px' }}>
          <Col>
            {!newBeer && (
              <Button onClick={handleAddBeer} type="primary">
                Lägg till öl
              </Button>
            )}
          </Col>
        </Row>
      </Content>

      <Modal
        title="Hjälp"
        open={isHelpModalVisible}
        onOk={() => setIsHelpModalVisible(false)}
        onCancel={() => setIsHelpModalVisible(false)}
        centered
      >
        <Typography.Paragraph>
          Här kan du hantera din öllista. Följ instruktionerna nedan för att använda sidan:
        </Typography.Paragraph>
        <Typography.Paragraph>
          1. För att lägga till en ny öl, klicka på "Lägg till öl" knappen.
        </Typography.Paragraph>
        <Typography.Paragraph>
          2. För att redigera en befintlig öl, klicka på "Ändra" knappen bredvid ölen du vill redigera.
        </Typography.Paragraph>
        <Typography.Paragraph>
          3. För att ta bort en öl, klicka på "Ta bort" knappen bredvid ölen du vill ta bort. Bekräfta sedan borttagningen.
        </Typography.Paragraph>
        <Typography.Paragraph>
          4. Glöm inte att spara dina ändringar genom att klicka på "Spara" knappen efter redigering eller tillägg.
        </Typography.Paragraph>
        <Typography.Paragraph>
          5. Du kan när som helst avbryta redigering eller tillägg genom att klicka på "Avbryt" knappen.
        </Typography.Paragraph>
      </Modal>

      <Modal
        title={
          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
            Ändra profil
          </div>
        }
        open={isProfileModalVisible}
        onOk={handleProfileUpdate}
        onCancel={() => setIsProfileModalVisible(false)}
        centered
      >
        <Form layout="vertical">
          <Form.Item label={<span style={{ fontWeight: 'bold' }}>Namn</span>}>
            <Input
              value={profileData.name}
              onChange={(e) => setProfileData({ ...profileData, name: e.target.value })}
            />
          </Form.Item>
          <Form.Item label={<span style={{ fontWeight: 'bold' }}>Email</span>}>
            <Input
              value={profileData.email}
              onChange={(e) => setProfileData({ ...profileData, email: e.target.value })}
            />
          </Form.Item>
          <Form.Item label={<span style={{ fontWeight: 'bold' }}>Klubb</span>}>
            <Input
              value={profileData.club}
              onChange={(e) => setProfileData({ ...profileData, club: e.target.value })}
            />
          </Form.Item>
          <Form.Item label={<span style={{ fontWeight: 'bold' }}>Telefonnummer</span>}>
            <Input
              value={profileData.phone}
              onChange={(e) => setProfileData({ ...profileData, phone: e.target.value })}
            />
          </Form.Item>
          <Form.Item>
            <Checkbox
              checked={profileData.brings_own_bar}
              onChange={(e) => setProfileData({ ...profileData, brings_own_bar: e.target.checked })}
            >
              Tar med egen bar
            </Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
};

export default BeerTable;
