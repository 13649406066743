import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import beerService from '../services/beerService';

const EditBeer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [beer, setBeer] = useState({
    name: '',
    style: '',
    abv: '',
    ibu: '',
    ebc: '',
    og: '',
    fg: '',
    brew_date: '',
  });

  useEffect(() => {
    const fetchBeer = async () => {
      const response = await beerService.getBeer(id);
      setBeer(response.data);
    };

    fetchBeer();
  }, [id]);

  const handleEditBeer = async (e) => {
    e.preventDefault();
    try {
      await beerService.updateBeer(id, beer);
      navigate('/beers');
    } catch (error) {
      console.error('Edit beer error', error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box mt={5}>
        <Typography variant="h4" gutterBottom>Edit Beer</Typography>
        <form onSubmit={handleEditBeer}>
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            value={beer.name}
            onChange={(e) => setBeer({ ...beer, name: e.target.value })}
          />
          <TextField
            label="Style"
            fullWidth
            margin="normal"
            value={beer.style}
            onChange={(e) => setBeer({ ...beer, style: e.target.value })}
          />
          <TextField
            label="ABV"
            fullWidth
            margin="normal"
            value={beer.abv}
            onChange={(e) => setBeer({ ...beer, abv: e.target.value })}
          />
          <TextField
            label="IBU"
            fullWidth
            margin="normal"
            value={beer.ibu}
            onChange={(e) => setBeer({ ...beer, ibu: e.target.value })}
          />
          <TextField
            label="EBC"
            fullWidth
            margin="normal"
            value={beer.ebc}
            onChange={(e) => setBeer({ ...beer, ebc: e.target.value })}
          />
          <TextField
            label="OG"
            fullWidth
            margin="normal"
            value={beer.og}
            onChange={(e) => setBeer({ ...beer, og: e.target.value })}
          />
          <TextField
            label="FG"
            fullWidth
            margin="normal"
            value={beer.fg}
            onChange={(e) => setBeer({ ...beer, fg: e.target.value })}
          />
          <TextField
            label="Brew Date"
            type="date"
            fullWidth
            margin="normal"
            value={beer.brew_date}
            onChange={(e) => setBeer({ ...beer, brew_date: e.target.value })}
            InputLabelProps={{ shrink: true }}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Save Changes
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default EditBeer;
