import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import beerService from '../services/beerService';
import beerStyles from './beerStyles';
import * as XLSX from 'xlsx';

const BeerList = () => {
  const [beers, setBeers] = useState([]);

  useEffect(() => {
    fetchAllBeers();
  }, []);

  const fetchAllBeers = async () => {
    try {
      const response = await beerService.getAll_Beers();
      setBeers(response.data.beers);
    } catch (error) {
      console.error('Error fetching beers:', error);
    }
  };

  const getFullStyleLabel = (style) => {
    if (!style) return 'Unknown style';
    const match = style.match(/(\d+)([A-Z])/);
    if (!match) return 'Invalid style';
    const [category, subcategory] = match.slice(1);
    const categoryObj = beerStyles[category];
    const subcategoryLabel = categoryObj ? categoryObj.options[subcategory] : 'Unknown subcategory';
    return `${style}: ${subcategoryLabel}`;
  };

  const styleSorter = (a, b) => {
    const aMatch = a.style.match(/(\d+)([A-Z])/);
    const bMatch = b.style.match(/(\d+)([A-Z])/);
    if (!aMatch || !bMatch) return 0;

    const aCategory = parseInt(aMatch[1], 10);
    const bCategory = parseInt(bMatch[1], 10);

    if (aCategory !== bCategory) {
      return aCategory - bCategory;
    }

    const aSubcategory = aMatch[2];
    const bSubcategory = bMatch[2];

    return aSubcategory.localeCompare(bSubcategory);
  };

  const timeChangedSorter = (a, b) => {
    if (!a.time_changed) return 1;
    if (!b.time_changed) return -1;
    return new Date(a.time_changed) - new Date(b.time_changed);
  };

//  const exportToExcel = () => {
//    const workbook = XLSX.utils.book_new();
//    const tavlingsID = beer.style && beer.id ? `${beer.style}:${beer.id}` : '';	  
//    const data = beers.map((beer) => ({
//      ID: beer.id,
//     Namn: beer.name,
//      Bryggare: beer.brewer_name,
//      Stil: beer.style,
//      ABV: beer.abv,
//      IBU: beer.ibu,
//      EBC: beer.ebc,
//      OG: beer.og,
//      FG: beer.fg,
//      Medbryggare: beer.cobrewer,
//      'Har egen bar': beer.brings_own_bar ? 'Ja' : 'Nej',
//      'Tid ändrad': beer.time_changed ? new Date(beer.time_changed).toLocaleString('sv-SE') : 'Unknown',
//      TävlingsID: tavlingsID,    
//    }));
//    const worksheet = XLSX.utils.json_to_sheet(data);
//    XLSX.utils.book_append_sheet(workbook, worksheet, 'Beers');
//    XLSX.writeFile(workbook, 'beer_list.xlsx');
//  };
  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const data = beers.map((beer) => {
      // Explicitly create the TävlingID before returning the object
      const tavlingsID = beer.style && beer.id ? `${beer.style}:${beer.id}` : '';

      return {
        ID: beer.id,
        Namn: beer.name,
        Bryggare: beer.brewer_name,
        Stil: beer.style,
        ABV: beer.abv,
        IBU: beer.ibu,
        EBC: beer.ebc,
        OG: beer.og,
        FG: beer.fg,
        Medbryggare: beer.cobrewer,
        'Har egen bar': beer.brings_own_bar ? 'Ja' : 'Nej',
        'Tid ändrad': beer.time_changed ? new Date(beer.time_changed).toLocaleString('sv-SE') : 'Unknown',
        TävlingsID: tavlingsID, // Use the explicitly created TävlingID
      };
    });

  const worksheet = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Beers');
  XLSX.writeFile(workbook, 'beer_list.xlsx');
};

  const columns = [
    {
      title: 'Namn',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Bryggare',
      dataIndex: 'brewer_name',
      key: 'brewer_name',
      sorter: (a, b) => a.brewer_name.localeCompare(b.brewer_name),
    },
    {
      title: 'Stil',
      dataIndex: 'style',
      key: 'style',
      sorter: styleSorter,
      render: (style) => getFullStyleLabel(style),
    },
    {
      title: 'ABV',
      dataIndex: 'abv',
      key: 'abv',
      sorter: (a, b) => a.abv - b.abv,
    },
    {
      title: 'IBU',
      dataIndex: 'ibu',
      key: 'ibu',
      sorter: (a, b) => a.ibu - b.ibu,
    },
    {
      title: 'EBC',
      dataIndex: 'ebc',
      key: 'ebc',
      sorter: (a, b) => a.ebc - b.ebc,
    },
    {
      title: 'OG',
      dataIndex: 'og',
      key: 'og',
      sorter: (a, b) => a.og - b.og,
    },
    {
      title: 'FG',
      dataIndex: 'fg',
      key: 'fg',
      sorter: (a, b) => a.fg - b.fg,
    },
    {
      title: 'Medbryggare',
      dataIndex: 'cobrewer',
      key: 'cobrewer',
      sorter: (a, b) => a.cobrewer.localeCompare(b.cobrewer),
    },
    {
      title: 'Har egen bar',
      dataIndex: 'brings_own_bar',
      key: 'brings_own_bar',
      sorter: (a, b) => (a.brings_own_bar === b.brings_own_bar ? 0 : a.brings_own_bar ? -1 : 1),
      render: (text) => (text ? 'Ja' : 'Nej'),
    },
    {
      title: 'Tid ändrad',
      dataIndex: 'time_changed',
      key: 'time_changed',
      sorter: timeChangedSorter,
      render: (text) => (text ? new Date(text).toLocaleString('sv-SE') : 'Unknown'),
    },
  ];

  return (
    <div>
      <Button onClick={exportToExcel} type="primary" style={{ marginBottom: '20px', marginTop: '10px', marginLeft: '10px' }}>
        Exportera till Excel
      </Button>
      <Table
        columns={columns}
        dataSource={beers}
        rowKey="id"
        pagination={false}
      />
    </div>
  );
};

export default BeerList;
