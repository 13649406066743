import React, { useState } from 'react';
import { Form, Input, Button, message, Typography, Layout, Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import authService from '../services/authService';
import '../App.css'; // Ensure this contains your global styles

const { Title } = Typography;
const { Content } = Layout;

const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleLogin = async (values) => {
    try {
      const response = await authService.login(values.email, values.password);
      localStorage.setItem('token', response.data.token);
      navigate('/beers');
    } catch (error) {
      console.error('Invalid credentials', error);
      message.error('Invalid credentials. Please try again.');
    }
  };

  return (
    <Layout style={{ minHeight: '100vh', justifyContent: 'center', alignItems: 'center' }}>
      <Content style={{ maxWidth: '400px', width: '100%' }}>
        <Card className="login-card">
          <div className="logo-container">
            <img src="/logo.png" alt="Logo" className="logo" />
            <Title level={3}>Registrering Höstölsträffen</Title>
          </div>
          <Form
            form={form}
            name="login"
            onFinish={handleLogin}
            layout="vertical"
          >
            <Form.Item
              label="E-post"
              name="email"
              rules={[{ required: true, message: 'Var vänlig ange din e-post adress!' }]}
            >
              <Input placeholder="Skriv din e-post adress" />
            </Form.Item>

            <Form.Item
              label="Lösenord"
              name="password"
              rules={[{ required: true, message: 'Var vänlig ange ditt lösenord!' }]}
            >
              <Input.Password placeholder="Skriv in ditt lösenord" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Logga in
              </Button>
            </Form.Item>
          </Form>
          <Button
            type="link"
            onClick={() => navigate('/register')}
            block
          >
            Skapa konto
          </Button>
          <div style={{ textAlign: 'center', marginTop: '16px' }}>
            <Typography.Text>
              Har du glömt ditt lösenord kontakta Hönö Ölagille på Facebook så hjälper vi dig.
            </Typography.Text>
          </div>
        </Card>
      </Content>
    </Layout>
  );
};

export default Login;
